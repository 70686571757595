<template>
  <a-result status="404" title="404" sub-title="对不起，您访问的页面不存在.">
    <template slot="extra">
      <a-button type="primary" @click="home">返回主页</a-button>
    </template>
  </a-result>
</template>
<script>
export default {
  name: '404',
  methods: {
    home() {
      this.$router.replace('/')
    }
  }
}
</script>
